import * as React from 'react'
import Layout from '../components/layout'

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact"
            snipit="">
      <dl className="rates text-center">
        <dt>Email</dt>
        <dd><a className="link-secondary" href="mailto:info@justessentialwellness.ca">info@justessentialwellness.ca</a></dd>

        <dt>Telephone</dt>
        <dd><a className="link-secondary" href="tel:+12501234567">(250)123-4567</a></dd>
      </dl>

    </Layout>
  )
}

export default ContactPage
